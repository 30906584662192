
  .signIn_Form {
    height: 100vh;
    background-color: rgb(214, 241, 241);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .productTitle {
    position: sticky;
    text-align: center;
    font-weight: 1000;
    padding-top: 80px;
    margin-bottom: 3rem;
    font-size: 32px;
  }

  .loginCard {
    position: sticky;
    max-width: 100vh;
  }

  .ant-card{
    border-radius: 10px;
  }

  .login-form {
    max-width: 100wh;
  }

  .login-form-forgot {
    float: right;
  }

  .ant-col-rtl .login-form-forgot {
    float: left;
  }

  .login-form-button {
    width: 100%;
  }

  .signUpLink {
    margin-top: 1rem;
    text-align: center;
  }
  