  
  .storeSelection > h3 {
    width: 806px;
    font-size: 16.5px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    line-height: 48px;
    color: rgb(52, 55, 61);
  }
  
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background-color: whitesmoke !important;
    border-radius: 6px !important;
  }
  
  .storeTypeButton{
    position: absolute !important;
    left: 320px !important;
    color: white !important;
  
  }
  
  .manageButton {
    position: absolute !important;
    display: flex !important;
    width: 220px !important;
    top: 138px !important;
    border: none !important;
    
  }
  
  #connectButton {
     position: absolute ;
    width: 240px ;
    top: 200px; 
    left: 79%; 
    height: 32px; 
    padding: 0 12px; 
    display: flex; 
    align-items: center; 
    justify-content: center;
    font-size: 14px; 
    line-height: 22px; 
    color: #005289FF; 
    background-color: #99D6FFFF; 
    opacity: 1; 
    border: none; 
    border-radius: 6px; 
    gap: 6px;
  } 
  
  .searchStore {
    position: absolute !important;
    width: 300px !important;
    height: 32px !important;
    top: 260px;
    left: 250px;
    opacity: 1;
    padding-right: 8px;
    font-size: 12px !important;
    background: #00000000 !important;
    border-radius: 4px !important;
    border-width: 1px;
    border-color: rgb(10, 10, 10);
    outline: none;
    color: #424955ff;
    border-color: #9095a0ff !important;
  }
  
  #filter {
    position: absolute;
    
    top: 253px;
    left: 570px;
    background-color: whitesmoke;
    border-radius: 6px;
    height: 36px;
  }
  
  .storeMappingTable {
    position: absolute;
    top: 330px;
    left: 240px;
    width: 80%;
    padding: 0 5px;
    background: #ffffffff;
    border-radius: 6px;
    border-width: 1px;
    border-color: #dee1e6ff;
    border: solid rgba(0, 0, 0, 0.05);
  }
  
  .ant-table-tbody > tr > td {
    padding: 6px;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .ant-table-thead > tr > th{
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    
  }