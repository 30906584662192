

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: whitesmoke;
    border-radius: 6px;
  }
  
  .storeTypeButton1, .storeTypeButton2 {
    position: absolute !important;
    left: 320px !important;
    color: white !important;
  }
  
  
  
  .searchProduct {
    position: absolute !important;
    width: 300px !important;
    height: 32px !important;
    top: 256px !important;
    left: 255px !important;
    opacity: 1 !important;
    padding-right: 8px !important;
    font-size: 12px !important;
    background: #00000000 !important;
    border-radius: 4px !important;
    border-width: 1px !important;
    border-color: #565e6cff !important;
    outline: none !important;
    color: #424955ff !important;
    border-color: #9095a0ff !important;
  }
  
  .filter {
    position: absolute !important;
  
    top: 253px;
    left: 570px !important;
    background-color: whitesmoke !important;
    border-radius: 6px !important;
    height: 36px !important;
  }
  
  .bulkMapperButton {
    display: inline !important;
    position: absolute !important;
   top: 185px !important;
   left: 85% !important;
   color: #005289FF !important;
   background-color: #99D6FFFF !important;
   border: none !important;
   border-radius: 6px !important;
  }

  .bulkMapperDisabled {
    cursor: not-allowed !important;
    border-color: #d9d9d9 !important;
    color: rgba(0,0,0,.25) !important;
    background-color: rgba(0,0,0,.04) !important;
    box-shadow: none !important;
  }
  
  .productTable {
    position: absolute;
      top: 330px;
      left: 240px;
      width: 80%;
      padding: 0 5px;
      background: #ffffffff;
      border-radius: 6px;
      border-width: 1px;
      border-color: #dee1e6ff;
      
  }
  
  .ant-table-tbody > tr > td {
    padding: 6px;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .ant-table-thead > tr > th{
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    
  }

  .ant-table-tbody > tr > td:nth-child(1) {
    text-align: left !important;
    padding-left: 15px !important;
  }
  .ant-table-thead > tr > th:nth-child(1) {
    text-align: left !important;    
  }