
  
.profileContent{
  background-color: whitesmoke;
}
.settings{
    position: absolute !important;
  }
  
  .settings >p {
    position: absolute  !important;
    font-weight: 700  !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    top: 60px !important; 
    left: 426px !important; 
    width: 768px !important; 
    height: 48px !important;
    font-size: 32px !important; 
    line-height: 48px !important; 
    color: #171A1FFF !important;
  }
  .settingsTabs{
    position: absolute !important; 
    top: 144px; 
    left: 410px; 
    width: 264px; 
    height: 32px; 
    display: flex; 
    align-items: center; 
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; 
    font-size: 14px; 
    line-height: 22px; 
    opacity: 1;
  }

  .settingsTabs > .ant-tabs-nav {
    margin-left: -175px;
  }
  
  .profileCard {
    position: absolute;
    top: 20px;
    left: -114px; 
    width: 668px; 
    height: 350px; 
    background: #FFFFFFFF; 
    border-radius: 6px; 
    box-shadow: 0px 0px 1px
  }
  
  .accountCard{
    position: absolute;
    top: 380px;
    left: -114px;
    width: 668px; 
    height: 220px; 
    background: #FFFFFFFF; 
    border-radius: 6px; 
    box-shadow: 0px 0px 1px
  }
  
  .name{
    display: flex;
  }

  .custom-file-upload {
    color: blue;
    border: 1px #d9d9d9 solid;
    border-radius: 6px;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    font-size: 14px;
    height: 32px;
    padding: 4px 15px;
    cursor: pointer;
  }