.ant-layout-header{
    background: white;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding: 2px;
    padding-left: 8px !important;
    
  }
  .logo {
    float: left;
    width: 190px;
    height: 100%;
    cursor: pointer;
    margin-left: 0px;
    margin-top: 0px;
  }

  .logo-container {
    display: flex;
    justify-content: center;
  }
 
  
  .logo > h2 {
    /* Text 2945 */
    position: absolute;
  top: 7px;
  width: 176px;
  font-size: 32px;
  line-height: 48px;
  color: #171a1fff;
  
  }
  
  .userProfile{
    float: right;
    padding-right: 10px;
  }
  
  .dropDownButton{
    background: transparent;
    border: none !important;
      
  }
  .ant-row-rtl .logo {
    float: right;
    
  }
    [data-theme="light"] .site-layout-content {
      background: #141414;
    }
    .site-layout-background {
      background: rgb(255, 255, 255);
    }