.site-layout-background {
    background: rgb(255, 255, 255);
  }
  
.ant-layout-sider-items{
    background-color: whitesmoke;
  }
  
  .ant-menu{
  background-color: whitesmoke;
  }
  .ant-layout{
    background-color: white;
  }

  .logOutButton{
    overflow: auto;
    margin-left: 1.5em;
    
    
    

  }
