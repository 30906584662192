
  .signUp_Card {
    overflow: auto;
    position: relative;
    height: 100vh;

  }
  .signUP_form {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100wh;
  }
  .name {
    display: flex;
  }
  .firstName{
    padding-right: 50px;
  }
 
  
  .signUp-form-button {
    width: 100%;
  }
  
  .leftPanel {
    padding-top: 100px;
    padding-left: 70px;
    display: flex;
    flex-direction: column;
  }
  .leftPanel > h2 {
    position: relative;
    font-weight: 1000;
    font-size: 2.5rem;
  }
  .agreement {
    float: inline-start;
  }
  .signUp-form {
    margin-top: 50px;
  }
  .signInLink {
    text-align: center;
    margin-top: 1rem;
  }
  