
  .forgotPasswordForm {
    height: 100vh;    
    background-color: rgb(214, 241, 241);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .productTitle {    
    position: sticky;
    text-align: center;
    font-weight: 1000;
    padding-top: 80px;
  }
  .forgotPasswordCard {    
    position: sticky;
    max-width: 100vh;    
  }
  
  .ant-card{
    border-radius: 10px;
  }
  .forgot-password-form {
    max-width: 100wh;
  }
 
  .resetPasswordButton {
    width: 100%;
  }

  .linkSentMessage {
    margin-top: 1rem;
    text-align: center;
    color: red;
  }

  .goBack {
    display: flex;
    justify-content: center;
  }
  